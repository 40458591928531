<div class="zs-row search-component">
    <div class="zs-col-md-12">
        <div class="zs-row">
            <div class="filter-view zs-col-md-3">
                <section class="zs-margin-1-1-0-0">
                    <ng-container *ngFor="let filter of filters">
                        <div class="zs-row" *ngIf="filter.fields && filter.fields.length > 0">
                            <div class="zs-accordion" [hidden]="!filter.applied">
                                <div *ngIf="filter.show" class="filter-option-div zs-row">
                                    <div *ngFor="let field of filter.fields; let idx = index" class="zs-col-md-3">
                                        <div *ngIf="field.type == searchConstants.SELECT_DROPDOWN" class="gridColumnMd">
                                            <div class="multiselect_dropdown">
                                                <app-multi-select-dropdown class="period-filter-dropdown"
                                                    #pastFilterSelect (selectedOptionsChange)="OnChangeFilter($event)"
                                                    [dataSource]="field.datasource" [label]="field.displayLabel"
                                                    [defaultText]="" [isAddSelectValue]="true" [isRequired]="true"
                                                    [isMultiple]="false" [minOptionsForSearch]="10">
                                                </app-multi-select-dropdown>
                                            </div>
                                            <!-- <div class="multiselect_dropdown" *ngIf="field.displayLabel != 'Sub type' ">
                                                <app-multi-select-dropdown class="period-filter-dropdown"
                                                    #pastFilterSelect (selectedOptionsChange)="OnChangeFilter($event)"
                                                    [dataSource]="field.datasource" [label]="field.displayLabel"
                                                    [defaultText]="" [isAddSelectValue]="true" [isRequired]="true"
                                                    [isMultiple]="false" [minOptionsForSearch]="10">
                                                </app-multi-select-dropdown>
                                            </div> -->
                                        </div>
                                        <div *ngIf="field.type == searchConstants.DATE_PICKER">
                                            <div class="date_picker">
                                                <label>
                                                    <app-zs-date-picker [label]="field.displayLabel"
                                                        [minDate]="field.min" class="date-picker-field"
                                                        [(selectedDate)]="field.selectedValue"
                                                        (selectedDateChange)="dateChange($event,field.displayLabel)">
                                                    </app-zs-date-picker>
                                                </label>
                                            </div>

                                        </div>
                                    </div>
                                    <div>
                                        <button class="zs-button apply-button zs-button-solid zs-size-s"
                                            (click)="selectedDateChange()">
                                            Apply
                                        </button>
                                        <button class="zs-button zs-size-s clear-button " (click)="clearFilter()">
                                            Reset
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="zs-row filter-option-div" style="display: flex;justify-content: space-between;">
                            <div class="zs-col-md-3 zs-margin-1-0.5-0-0 "
                                *ngFor="let card_detail of card_details; let idx = index">
                                <div class="zs-card kpi-card zs-padding-2" *ngIf="!card_detail.others"
                                    (click)="filterRow(card_detail.filterValue)"
                                    [ngClass]="[card_detail.isSelected?'active':'',card_detail.filterValue == 'approved'?'approved':card_detail.filterValue == 'pending'?'pending':card_detail.filterValue == 'processed'?'processed':card_detail.filterValue == 'rejected' ?'rejected':'']">
                                    <span class="card-heading zs-display-block">
                                        <h3 class="zs-margin-0-0-0-0">{{card_detail.heading}}</h3>
                                        <div class="card-content">
                                            <p class="zs-font-weight-bold zs-size-l profile-detail-label ">
                                                {{card_detail.value}}
                                            </p>
                                            <span *ngIf="card_detail.heading == 'Rejected'"
                                                class="zs-icon zs-size-xxl zs-icon-dislike"></span>
                                            <span *ngIf="card_detail.heading == 'Approved'"
                                                class="zs-icon zs-size-xxl zs-icon-like"></span>
                                            <span *ngIf="card_detail.heading == 'Total DCRs'"
                                                class="zs-icon zs-size-xxl zs-icon-reports"></span>
                                            <span *ngIf="card_detail.heading == 'Processed'"
                                                class="zs-icon zs-size-xxl zs-icon-refresh"></span>
                                        </div>
                                    </span>
                                </div>
                                <div class="zs-card kpi-card zs-padding-2" *ngIf="card_detail.others"
                                    (click)="filterRow(card_detail.filterValue)"
                                    [ngClass]="[card_detail.isSelected?'active':'',card_detail.filterValue == 'approved'?'approved':card_detail.filterValue == 'pending'?'pending':card_detail.filterValue == 'rejected' ?'rejected':'']">
                                    <span class="zs-display-block">
                                        <div class="pending-header zs-display-flex"
                                            (click)="filterRow(card_detail.heading);">
                                            <h3>{{card_detail.heading}} &nbsp;{{card_detail.value}}</h3>
                                        </div>
                                        <div>
                                            <div *ngIf="card_detail.others" class="pending-details">
                                                <span class="zs-col-1 zs-row-1 zs-display-flex"
                                                    *ngFor="let item of card_detail.others">
                                                    <span class="profile-detail-label zs-body-small zs-counter"
                                                        (click)="filterRow(item.heading);$event.stopPropagation();">
                                                        {{item.heading}}
                                                    </span>
                                                    <p class="zs-font-weight-bold zs-margin-2-0-0-1">
                                                        {{item.value}}
                                                    </p>
                                                </span>
                                                <span
                                                    class="zs-icon zs-size-xxl zs-icon-clock-pending zs-margin-0.5-0-0-0"></span>
                                            </div>
                                        </div>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="table-content zs-margin-2-0-0-0">
                            <div class="table-header zs-margin-2-0-3-0">
                                <div>
                                    <h4>Change Request Details</h4>
                                </div>
                                <div>
                                  <!-- removed this button for Geron -->
                                    <!-- <button class="zs-button zs-size-s  zs-border-rounded-0" (click)="synCRMData()">
                                        <span class="zs-icon zs-size-large zs-icon-refresh language-icon-globe"></span>
                                        Sync data <zs-loader class="zs-loader zs-inverse" *ngIf="showSyncLoader">
                                        </zs-loader>
                                    </button> -->
                                    <button class="zs-button zs-size-s  zs-border-rounded-0" (click)="exportToExcel()">
                                        <span class="zs-icon zs-size-large zs-icon-download language-icon-globe"></span>
                                        Download file <zs-loader class="zs-loader zs-inverse" *ngIf="showExcelLoader">
                                        </zs-loader><span></span>
                                    </button>
                                </div>
                            </div>
                            <div class="ag-grid-component">
                                <div class="grid-table-loader">
                                    <div *ngIf="showAgGridLoader" class="loader">
                                        <span class="loader-position" appZsLoader [isVisible]="showAgGridLoader"
                                            [isWrapper]="false">
                                        </span>
                                    </div>

                                    <div class="zs-ag-grid custom-scroll">
                                        <ag-grid-angular class="ag-theme-alpine" [columnDefs]="colDefs"
                                            [gridOptions]="gridOptions" [rowData]="rowData" [enableSorting]="true"
                                            [frameworkComponents]="frameworkComponents" [suppressCellSelection]="true"
                                            [enableCellTextSelection]="true" [domLayout]="domLayout"
                                            [suppressLoadingOverlay]="true" [overlayNoRowsTemplate]="noRowsTemplate"
                                            (gridReady)="onGridReady($event)">
                                        </ag-grid-angular>
                                    </div>
                                </div>
                            </div>
                            <div class="zs-display-flex pagination-block">
                                <app-zs-pagination [itemCount]="rowCount" [currentPage]="page" [pageSize]="pageSize"
                                    (pageChange)="pageChanged($event);">
                                </app-zs-pagination>
                            </div>
                        </div>
                    </ng-container>
                </section>
            </div>

        </div>
    </div>
</div>
